import React from "react";
import categoryLinkService from "../../services/category/categoryLinkService";
import RoutePrefix from "../../services/route/prefix";
import {setIsOpen} from "../../stores/brand/brandSlice";
import {connect} from "react-redux";
import {withRouter} from "next/router";
import Link from "next/link";

class NavigationSubChilds extends React.PureComponent<any, any> {
    pathIds: number[];
    categories: any[];
    currentId: number;
    routePrefix: any;

    constructor(props: any, context: any) {
        super(props, context);
        this.pathIds = props.pathIds;
        this.categories = props.categories;
        this.currentId = props.currentId;
        this.routePrefix = props.routePrefix ?? RoutePrefix.listing;
    }

    closeOffCanvas() {
        if (this.props.setIsOpen) this.props.setIsOpen(false);
    }

    render() {
        return (
            <>
                {
                    this.categories.map((category: any, index: number) => (
                        <li key={index}>
                            <Link
                                href={categoryLinkService.getLink(category, this.routePrefix)}
                                // scroll={true}
                                onClick={() => this.closeOffCanvas()}
                                prefetch={false}
                                className={(this.pathIds.includes(category.id) || category.id === this.currentId) ? 'active primary-color' : ''}>
                                {category.name}
                            </Link>
                            {('children' in category && category.children.length) ? (
                                <ul className="list-unstyled">
                                    <NavigationSubChilds categories={category.children}
                                                         routePrefix={RoutePrefix.brandListing}
                                                         pathIds={this.pathIds}
                                                         currentId={this.currentId}/>
                                </ul>
                            ) : <></>}
                        </li>
                    ))
                }
            </>
        )
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        setIsOpen: (isOpen: boolean) => dispatch(setIsOpen(isOpen)),
    }
}

const mapStateToProps = (state: any) => {return {}}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(NavigationSubChilds))