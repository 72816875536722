class ContentParser {
    parse (contents: any) {
        try {
            return JSON.parse(contents);
        }
        catch(e) {
            return false;
        }
    }
}

const contentParser = new ContentParser();
export default contentParser