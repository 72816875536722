import React from "react";

interface Props {
    sidebar: any,
    content: any,
    hide?: boolean,
    reverse?: boolean,
    disableSidebar?: boolean
}

export default function ContentSidebarLeft({sidebar, content, hide = true, reverse = false, disableSidebar = false}: Props) {
    if (disableSidebar) {
        return (
            <div className="mt-5">
                {content}
            </div>
        )
    }

    return (
        <div className="row mt-5">
            <div
                className={`d-none col-lg-3 ${!hide && 'd-lg-block'} fs-8 ${reverse && 'order-2 order-lg-1 mt-5 mt-md-0'}`}>
                {sidebar}
            </div>
            <div className={`col-12 ${hide ? 'col-lg-12' : 'col-lg-9'} ${reverse && 'order-1 order-lg-2'}`}>
                {content}
            </div>
        </div>
    )
}