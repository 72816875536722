import React from "react";
import i18next from "i18next";
import NavigationSubChilds from "./SubChilds";
import Link from "../link/Link";
import RoutePrefix from "../../services/route/prefix";
import slugify from "../../services/slugify";
import {withRouter} from "next/router";
import categoryLinkService from "../../services/category/categoryLinkService";
import {connect} from "react-redux";
import {setIsOpen} from "../../stores/brand/brandSlice";

class NavigationBrand extends React.PureComponent<any, any> {
    categories: any;
    brand: any;
    pathIds: any;
    category: any;
    currentId: any;
    currentLink?: any;

    constructor(props: any, context: any) {
        super(props, context);
        this.categories = props.categories;
        this.brand = props.brand;
        this.pathIds = props.pathIds;
        this.category = props.category;
        this.currentId = props.currentId;
        this.currentLink = props.currentLink;
    }

    isBrandActive() {
        if (this.props.router.pathname !== "/b/[brand]") return false;
        return !!this.props.router.asPath.includes(this.brand.name.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase());
    }

    linkClickHandler(category: any) {
        if (('children' in category)) return;
        this.props.setIsOpen(false);
    }

    isActive(category: any) {
        if ((!this.currentId || !this.pathIds.length) && this.currentLink) {
            if (category.external === `/${i18next.language}/${this.currentLink}`) return true;
        }

        if (this.pathIds.includes(category.id) || category.id === this.currentId) return true;
        return false;
    }

    render() {
        if (!this.categories) return <></>

        return (
            <>
                <ul className="list-unstyled nav-side">
                    <li>
                        <Link
                            // href={categoryLinkService.getLink(this.brand, RoutePrefix.brand)}
                            href={`/${i18next.language}/${slugify(this.brand.name.toLowerCase())}`}
                            className={`fw-bold ${this.isBrandActive() ? 'primary-color' : ''}`}>
                            {this.brand.name}
                        </Link>
                    </li>

                    {this.categories.map((category: any, index: number) => (
                        <li key={index} data-id={category.id}
                            className={`${this.isActive(category) && 'open active'}`}>
                            <Link
                                href={categoryLinkService.getLink(category, RoutePrefix.brandListing)}
                                className={`${this.isActive(category) && 'fw-bold active primary-color'} d-flex flex-row justify-content-between`}
                                onClick={() => this.linkClickHandler(category)}>
                                <span>{category.name}</span>
                                {(category.children && category.children.length) && (<i className="fa-solid fa-chevron-down fw-bold me-2"></i>)}
                            </Link>
                            {('children' in category) && (
                                <ul className="list-unstyled mt-1">
                                    <NavigationSubChilds
                                        categories={category.children}
                                        routePrefix={RoutePrefix.brandListing}
                                        currentId={this.currentId}
                                        pathIds={this.pathIds}/>
                                </ul>
                            )}
                        </li>
                    ))}
                </ul>
            </>
        )
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        setIsOpen: (isOpen: boolean) => dispatch(setIsOpen(isOpen)),
    }
}

const mapStateToProps = (state: any) => { return {}}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(NavigationBrand))