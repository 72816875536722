import React from "react";
import i18next from "i18next";
import Link from "../../link/Link";
import categoryLinkService from "../../../services/category/categoryLinkService";

export default class BreadcrumbBrand extends React.PureComponent<any, any> {
    brand: any;
    list: any = {
        de: 'marken',
        en: 'brands',
        fr: 'marques'
    }

    constructor(props: any, context: any) {
        super(props, context);
        this.brand = props.brand;
    }

    render() {
        return (
            <nav className="breadcrumb-divider" aria-label="breadcrumb" itemType="https://schema.org/BreadcrumbList">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item" itemProp="itemListElement" itemType="https://schema.org/ListItem">
                        <link itemProp="url" href={`${process.env.NEXT_PUBLIC_APP_URL}/${i18next.language}/${this.list[i18next.language]}`}/>
                        <Link href={`/${i18next.language}/${this.list[i18next.language]}`} passHref>
                            <span itemProp="name">{i18next.t('general.brands').toString()}</span>
                        </Link>
                    </li>
                    <li className="breadcrumb-item" itemProp="itemListElement" itemType="https://schema.org/ListItem">
                        <link itemProp="url" href={`${process.env.NEXT_PUBLIC_APP_URL}/${categoryLinkService.getLink(this.brand)}`}/>
                        <Link href={categoryLinkService.getLink(this.brand)}>
                            <span itemProp="name">{this.brand.name}</span>
                        </Link>
                    </li>
                </ol>
            </nav>
        )
    }
}