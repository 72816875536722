import contentRenderer from "../../services/content/contentRenderer";
import Container from "../layouts/Container";

export default function ContentPage({contents, container, className}: any) {
    let list: any = Array.isArray(contents.documentBlockList) ? contents.documentBlockList : JSON.parse(contents.documentBlockList);
    let isDarkTheme = contents.darkBackground !== undefined && contents.darkBackground == true ? 'dark-theme-content' : '';
    let backgroundColor = contents.backgroundColor !== undefined ? {
        backgroundColor: `${contents.backgroundColor}`,
        padding: '1rem'
    } : {};

    try {
        return (
            <Container className={`category-content ${className}`} contentHeight={''} mb={''} container={container}>
                <div className={isDarkTheme} style={backgroundColor}>
                    {list.map((doc: any) => (
                        <div key={doc.id} className={"mb-4"}>
                            {contentRenderer.renderComponent(doc.type, doc)}
                        </div>
                    ))}
                </div>
            </Container>
        )
    } catch (e) {
        console.error(e);
        return <></>
    }
}