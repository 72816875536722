import React from "react";
import Image from "next/image";

export default class Banner extends React.PureComponent<any, any> {
    render() {
        if (!this.props.image) return <></>

        return (
            <div className={`container g-0 border-light rounded-2 ${this.props.className}`}>
                <Image loading="lazy"
                       alt={this.props.imageAlt ?? ''}
                       src={this.props.image}
                       height={383}
                       width={1400}
                       className={"w-100 h-auto rounded-2"}
                       style={{height: "383px"}}/>
            </div>
        )
    }
}