import i18next from "i18next";
import {SwipeableDrawer} from "@mui/material";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {State} from "../../../stores/store";
import {setIsOpen} from "../../../stores/brand/brandSlice";
import NavigationBrand from "../../navigation/Brand";

export default function BrandNavigationOffCanvas({categories, pathIds, brand, category, currentLink}: any) {
    const isOpen = useSelector((state: State) => state.brandStore.isOpen);
    const dispatch = useDispatch();

    const toggleDrawer = () =>
        (event: React.KeyboardEvent | React.MouseEvent) => {
            if (
                event &&
                event.type === 'keydown' &&
                ((event as React.KeyboardEvent).key === 'Tab' ||
                    (event as React.KeyboardEvent).key === 'Shift')
            ) {
                return;
            }
            dispatch(setIsOpen(!isOpen))
        };

    return (
        <>
            <div className="btn-alt rounded-2 ps-3 pe-3 pt-2 pb-2 d-lg-none mb-4">
                <span className="d-block text-center" aria-label={"Cart"} role="button" onClick={toggleDrawer()}>
                  <span className="">Mehr zu {brand.name}</span>
                </span>
            </div>
            <SwipeableDrawer
                anchor={'left'}
                open={isOpen}
                onClose={toggleDrawer()}
                onOpen={toggleDrawer()}>
                <div className="position-relative mui-offcanvas">
                    <div className="ps-3 pe-3 pt-2 pb-3 mb-3 bg-dark text-white" onClick={toggleDrawer()} role="button">
                        <i className="fa-solid fa-circle-xmark p-3"></i>
                        <span className="ms-2 fw-bold">{i18next.t('general.close').toString()}</span>
                    </div>
                    {/*<hr className={"primary-color"}/>*/}

                    <div className={"ms-4 me-4"}>
                        <NavigationBrand
                            key={brand ? brand.id : category.id}
                            brand={brand}
                            pathIds={pathIds}
                            currentId={category ? category.id : null}
                            category={category}
                            currentLink={currentLink}
                            categories={categories}/>
                    </div>
                </div>
            </SwipeableDrawer>
        </>
    )
}